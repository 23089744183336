import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { DeliveryFeeDto, UpdateDeliveryFeeDto, CreateDeliveryFeeDto } from "../dto"

@Injectable()
export class DeliveryFeeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<DeliveryFeeDto[]>('/merchant/v1/delivery-fee')
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/merchant/v1/delivery-fee/${id}`)
  }

  async update(id: string, updateDeliveryFeeDto: UpdateDeliveryFeeDto) {
    return await this.httpService.put<boolean>(`/merchant/v1/delivery-fee/${id}`, updateDeliveryFeeDto)
  }

  async create(createDeliveryFeeDto: CreateDeliveryFeeDto) {
    return await this.httpService.post<boolean>(`/merchant/v1/delivery-fee`, createDeliveryFeeDto)
  }
}