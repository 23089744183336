import { Routes } from '@angular/router'

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./+merchant/merchant.module').then(m => m.MerchantModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./+admin/admin.module').then(m => m.AdminModule)
  }
]
