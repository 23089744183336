import { BaseDto } from './base.dto'

import { FileDto } from './file.dto'
import { PagingDto } from './paging.dto'
import { TenantDto } from './tenant.dto'
import { UserDto } from './user.dto'

export enum OrderStatus {
  NEW = 'new',
  COMPLETED = 'completed',
  READY_FOR_PICKUP = 'ready_for_pickup',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  REFUND = 'refund',
  SHIPPING = 'shipping',
  ACCEPTED = 'accepted',
  DROP_OFF = 'drop_off',
  ON_THE_WAY = 'on_the_way',
  DELIVERING = 'delivering',
  PENDING = 'pending',
  DELIVERED = 'delivered',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  REQUEST_CANCEL = 'request_cancel',
  REQUEST_RETURN = 'request_return'
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export enum PaymentMethod {
  COD = 'cod',
  PAY_WAY = 'payway'
}

export class OrderDto extends BaseDto {
  orderNumber: string
  owner: UserDto
  paidStatus: string
  discountTotal: number
  total: number
  shippingMethod: string
  customerNote: string
  pickupDate: string
  pickupTime: string
  vat: number
  vatRate: number
  paymentStatus: string
  membershipDiscount: MembershipDiscountDto
  membershipDiscountTotal: number
  couponData: CouponDataDto
  couponTotal: number
  items: OrderItemDto[]
}

export class MembershipDiscountDto extends BaseDto {
  discount: number
  discountType: number
  cardType: string
  products: MembershipProductDto[]
}

export class MembershipProductDto {
  slug: string
  name: string
  sku: string
  salePrice: number
  price: number
  quantity: number
  discountPercentage: number
  discountPrice: number
  saleDiscountPrice: number
}

export class OrderDetailDto extends BaseDto {
  paymentMethod: string
  paymentStatus: string
  status: string
  logs: any
  shippingAddress: any
  reject: any
  cancel: any
  vat: number
  vatRate: number
  reviewed: boolean
  owner: UserDto
  shippingTotal: number
  trackingNumber: string
  subTotal: number
  total: number
  paidStatus: string
  items: OrderItemDto[]
  blueTechOrderNumber: string
  couponData: CouponDataDto
  orderNumber: string
  discountTotal: number
  voucherTotal: number
  couponTotal: number
  tenant: TenantDto
  d2dCreatedStatus: string
  d2dBookingNumber: string
  d2dTrackingUrl: string
  d2dPickUpNumber: string
  d2dDeliveryStatus: string
  blueTechCreateStatus: string
  abaCompletePreAuthStatus: string
  abaCancelPreAuthStatus: string
  mallVoucherShareTotal: number
  mallCouponShareTotal: number
  order: OrderDto
}

export class OrderItemDto {
  quantity: number
  unitPrice: number
  total: number
  subTotal: number
  reviewed: boolean
  unitSalePrice: number
  discountTotal: number
  productDescription: string
  productSlug: string
  thumbnail: FileDto
  productEnabled: boolean
  productName: string
  productAttributes: any[]
}

export class CountStatusDto {
  totalOfNew: number
  totalOfAccepted: number
  totalOfDelivering: number
}

export class CouponDataDto {
  amount: number
  minOrder: number
  title: string
  description: string
  tenantId: string
  discountType: string
  userType: string
  products: CouponProductDto[]
  type: string
  code: string
  productType: string
  expiredDate: string
  isExpired: boolean
}

export class CouponProductDto extends BaseDto {
  slug: string
  name: string
  sku: string
  salePrice: number
  price: number
  quantity: number
  discountPercentage: number
  discountPrice: number
  saleDiscountPrice: number
}

export class FindOrderDto extends PagingDto {
  public searchText?: string
  public status?: string
  public startDate?: Date
  public endDate?: Date
}

export class OrderBadgeCountDto {
  newCount: number
  completedCount: number
  cancelledCount: number
  totalCount: number
}

export class OrderRejectDto {
  reason: any

  static create() {
    return new OrderRejectDto
  }
}

export class ExportOrderDto {
  status?: string
  startDate?: string
  endDate?: string
  timezone?: string
}