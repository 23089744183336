import { BaseDto } from "./base.dto"

import { FileDto } from "./file.dto"

export class BannerOnboardDto extends BaseDto {
  text: string
  image: FileDto
  enabled: boolean

  static create() {
    return new BannerOnboardDto()
  }
}

export class CreateBannerOnboardDto {
  text?: string
  imageId: string
}

export class UpdateBannerOnboardDto {
  text?: string
  imageId?: string
  enabled?: boolean
}