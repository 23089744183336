import { BaseDto } from "./base.dto"

import * as _ from 'lodash'

import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"

export class FindTrendingSearchDto extends PagingDto {
  searchText?: string
}

export class TrendingSearchDto extends BaseDto {
  text?: TranslationDto
  enabled?: boolean

  static create(data: TrendingSearchDto) {
    return _.assign(new TrendingSearchDto(), {
      id: data?.id || null,
      text: data?.text || TranslationDto.create()
    })
  }
}

export class UpdateTrendingSearchDto {
  text?: TranslationDto
}

export class CreateTrendingSearchDto {
  text: TranslationDto
}

export class SortOrderTrendingSearchDto {
  ids: string[]
}