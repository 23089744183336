import * as _ from 'lodash'
import { BaseDto } from './base.dto'

import { FileDto } from './file.dto'

import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class FindMallServicePageDto extends PagingDto {
  public searchText?: string
}

export class MallServicePageDataDto {
  titleEn: string
  titleKh: string
  link: string

  static create() {
    return new MallServicePageDataDto()
  }
}

export class MallServicePageDto extends BaseDto {
  title: TranslationDto
  content: TranslationDto
  slug: string
  image?: FileDto
  data: MallServicePageDataDto
  enabledLinkButton: boolean

  static create() {
    return _.assign<MallServicePageDto>({
      id:'',
      title : TranslationDto.create(),
      content : TranslationDto.create(),
      slug : '',
      data: MallServicePageDataDto.create()
    })
  }
}

export class CreateMallServicePageDto {
  title: TranslationDto
  content: TranslationDto
  slug: string
  imageId?: string
  data?: any
  enabledLinkButton?: boolean
}

export class UpdateMallServicePageDto {
  title?: TranslationDto
  content?: TranslationDto
  slug?: string
  imageId?: string
  data?: any
  enabledLinkButton?: boolean
}