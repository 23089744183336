export class MerchantTenantExportDto {
    statuses?: string[]
    startDate?: Date
    endDate?: Date
    timezone?: string
}

export class MerchantVoucherExportDto extends MerchantTenantExportDto {
    
}
