import { assign } from 'lodash'

export class DateOfBirthDto {
  date: number
  month: number
  year: number

  static create() {
    return assign<DateOfBirthDto>({
      date: 10,
      month: 10,
      year: 1996
    })
  }
}
