import * as _ from 'lodash'

import { BaseDto } from "./base.dto"

import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"

export class FindMenuDto extends PagingDto {
  searchText?: string
}

export class MenuDto extends BaseDto {
  badgeBgColor?: string
  title?: TranslationDto
  badgeTitle?: TranslationDto
  image?: FileDto
  mobileImage?: FileDto
  enabled?: boolean
  link?: string

  static create({ id, title, link, badgeBgColor, badgeTitle }: MenuDto) {
    return _.assign(new MenuDto(), {
      id: id || null,
      title: title || TranslationDto.create(),
      badgeTitle: badgeTitle || TranslationDto.create(),
      badgeBgColor,
      link: link || null
    })
  }
}

export class CreateMenuDto {
  title?: TranslationDto
  imageId?: string
  mobileImageId?: string
  link?: string
  badgeTitle?: TranslationDto
  badgeBgColor?: string
  enabled?: boolean
}