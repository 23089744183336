import { BaseDto } from "./base.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { PagingDto } from "./paging.dto"
import { UserDto } from "./user.dto"
import { MallOrderDto } from "./mall-order.dto"

export class MallCouponCodeDto {
  id: string
  couponCode: string
  limitUsage: number
  usedCount: number
  isValid: boolean
}

export class CreateMallCouponDto {
  ownerIds?: string[]
  // khmerTitle: string
  enTitle: string
  amount: number
  minOrder: number
  discountType: string
  givenType: string
  limitUsage?: number
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  tenantId?: string
  type: string
  codeType: string
  couponCode?: string
  numberOfCode: number
  prefix?: string

  static create() {
    return new CreateMallCouponDto()
  }
}

export class FindMallCouponDto extends PagingDto {
  status?: string
  searchText?: string
}

export class ExportHistoryDto {
  startDate?: Date
  endDate?: Date
}

export class MallCouponDto extends BaseDto {
  // khmerTitle: string
  enTitle: string
  amount: number
  limitUsage: number
  discountType: string
  givenType: string
  codeType: string
  enabled: boolean
  creator: UserDto
  couponCodes: MallCouponCodeDto[]
  minOrder: number
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  ownerIds: string[]
  tenantId: string
  type: string
  numberOfCode: number
  prefix: string
  
  static create() {
    return new MallCouponDto()
  }
}

export class MallCouponHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  couponTotal: number
  couponCode: string
  discountAmount: number
  discountType: string
  mallOrder: MallOrderDto
  mallCoupon: MallCouponDto

  static create() {
    return new MallCouponHistoryDto()
  }
}