import _ from "lodash"

import { BaseDto } from "./base.dto"
import { MallVoucherDto } from "./mall-voucher.dto"

import { OrderDto } from "./order.dto"
import { UserDto } from "./user.dto"
import { MallCouponDto } from "./mall-coupon.dto"

export class MallOrderDto extends BaseDto {
  type: string
  mallOrderNumber: string
  status: string
  owner: UserDto
  paymentStatus: string
  paymentMethod: string
  total: number
  mallVoucher: MallVoucherDto
  mallCoupon: MallCouponDto
  tenantOrders: OrderDto[]
  mallVoucherData: any
  mallCouponData: any

  static create() {
    return new MallOrderDto()
  }
}

export class MallOrderDetailDto extends BaseDto {
  type: string
  mallOrderNumber: string
  mallVoucher: MallVoucherDto
  mallCoupon: MallCouponDto
  status: string
  owner: UserDto
  paymentStatus: string
  paymentMethod: string
  total: number
  tenantOrders: OrderDto[]
  logs: any
  mallVoucherData: any
  shippingAddress: any
  shippingTotal: number
  couponTotal: number
  voucherTotal: number
  discountTotal: number
  subTotal: number
  completedAt: Date
  nham24BookedSuccess: boolean
  numberOfPackages: number
  mallCouponData: any
  tenantCouponData: any
}

export class OrderTrackingDto {
  status: string
  date: string
  time: string
  subtitle: string

  static create(data: OrderTrackingDto) {
    return _.assign(new OrderTrackingDto(), data)
  }
}