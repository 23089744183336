import { BaseDto } from "./base.dto"
import { TranslationDto } from "./translation.dto"
import * as _ from 'lodash'

export class HomeSectionTitleParamKeyDto extends BaseDto {
  key: string
  enabled: boolean
  title: TranslationDto
}

export class HomeSectionTitleUpdateDto {
  title: TranslationDto
  static create() {
    return _.assign(new HomeSectionTitleUpdateDto(), {
      title: TranslationDto.create()
    })
  }
}