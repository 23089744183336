import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"

export class PickupCounterDto extends BaseDto {
  khmerNote: string
  englishNote: string
  khmerFloor: string
  englishFloor: string
  contact: string
  time: string
  image: FileDto
  mobileImage: FileDto
  lastModifiedAt: Date

  static create() {
    return new PickupCounterDto()
  }
}

export class FindPickupCounterDto extends PagingDto {
  
}

export class CreatePickupCounterDto {
  khmerNote?: string
  englishNote?: string
  khmerFloor?: string
  englishFloor?: string
  contact?: string
  time?: string
  imageId?: string
  mobileImageId?: string
}