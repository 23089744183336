import * as _ from 'lodash'

import { BaseDto } from "./base.dto"

import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"

export class FindMallServiceMenuDto extends PagingDto {
  searchText?: string
}

export class MallServiceMenuDto extends BaseDto {
  title?: TranslationDto
  image?: FileDto
  mobileImage?: FileDto
  enabled?: boolean
  link?: string

  static create({ id, title, link }: MallServiceMenuDto) {
    return _.assign(new MallServiceMenuDto(), {
      id: id || null,
      title: title || TranslationDto.create(),
      link: link || null
    })
  }
}

export class CreateMallServiceMenuDto {
  title?: TranslationDto
  imageId?: string
  mobileImageId?: string
  link?: string
  enabled?: boolean
}