import * as _ from 'lodash'
import { FileDto } from './file.dto'

import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class FindInventoryItemDto extends PagingDto {
  searchText?: string
}

interface ProductAttribute {
  name: string
  value: string
}

export class InventoryItemDto {
  id: string
  name: TranslationDto
  sku: string
  thumbnail: FileDto
  stockQuantity: number
  lowStockAlertQuantity: number
  productAttributes: ProductAttribute[]
  children: InventoryItemDto[]
}

interface ItemDto {
  id: string
  stockQuantity: number
}

export class UpdateInventoryItemDto {
  items: ItemDto[]
}

export class CountInventoryDto {
  totalInventory: number
  totalLowInventory: number
}