import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'
import { DragulaService } from 'ng2-dragula'


import { 
  HttpService, 
  AuthService, 
  LocalStorageService, 
  FileService, 
  ProfileService, 
  SettingService, 
  ProductService, 
  OrderService, 
  CategoryService, 
  ProductDiscountService, 
  InventoryService, 
  DashboardService, 
  BannerService, 
  TenantService, 
  ProductReviewService, 
  FloorMapService, 
  TenantVoucherCampaignService, 
  pageService, 
  WindowRef,
  MerChantReportService, 
  TenantCouponService,
  ShippingService, ChoiceGroupService, DeliveryFeeService, AdvertisementService, AllowPhoneNumberService, 
  PromotionService} from '@api/services'

import { ApiModuleOptions } from './api.module-options'
import { AdminFeaturedBrandService } from '@api/services/featured-brand.service'
import { RemoveDeletedPipe } from '../utils/user-name.pipe'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe,
    RemoveDeletedPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    pageService,
    BannerService,
    ChoiceGroupService,
    DeliveryFeeService,
    AdminFeaturedBrandService,
    AdvertisementService,
    MerChantReportService,
    ProfileService,
    ProductReviewService,
    SettingService,
    ProductService,
    TenantService,
    PromotionService,
    TenantVoucherCampaignService,
    AllowPhoneNumberService,
    OrderService,
    DragulaService,
    FloorMapService,
    WindowRef,
    CategoryService,
    ProductDiscountService,
    TenantCouponService,
    InventoryService,
    DashboardService,
    ShippingService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe,
    RemoveDeletedPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
