import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"

import * as _ from 'lodash'

export class FindGalleryDto extends PagingDto {
  searchText?: string
}

export class GalleryDto extends BaseDto {
  title?: TranslationDto
  enabled?: boolean
  link?: string
  publishedNotification?: boolean
  mobileThumbnail?: FileDto
  thumbnail?: FileDto

  static create({ id, title, enabled, thumbnail, mobileThumbnail, link }: GalleryDto) {
    return _.assign(new GalleryDto(), {
      id: id || null,
      title: title || TranslationDto.create(),
      thumbnail: thumbnail || null,
      mobileThumbnail: mobileThumbnail || null,
      link: link || null
    })
  }
}

export class CreateGalleryDto {
  title?: TranslationDto
  enabled?: boolean
  thumbnailId?: string
  mobileThumbnailId?: string
  link?: string
}