import * as _ from 'lodash'

import { BaseDto } from "./base.dto"
import { FileDto } from './file.dto'
import { PagingDto } from './paging.dto'
import { TranslationDto } from './translation.dto'

export class TenantCategoryDto extends BaseDto {
  name?: TranslationDto
  image?: FileDto
  mobileImage?: FileDto
  enabled?: boolean
  khImage?: FileDto
  khMobileImage?: FileDto

  static create({ id, name, image, mobileImage, khMobileImage, khImage }: TenantCategoryDto) {
    return _.assign<TenantCategoryDto>({
      id: id || null,
      name: name || TranslationDto.create(),
      image: image || null,
      mobileImage: mobileImage || null,
      khImage: khImage || null,
      khMobileImage: khMobileImage || null
    })
  }
}

export class FindTenantCategoryDto extends PagingDto {
  searchText?: string
  enabled?: string
}

export class CreateTenantCategoryDto {
  name: TranslationDto
  imageId: string
  mobileImageId: string
  khImageId: string
  khMobileImageId: string
}

export class UpdateTenantCategoryDto {
  name?: TranslationDto
  imageId?: string
  mobileImageId?: string
  enabled?: boolean
  khImageId?: string
  khMobileImageId?: string
}