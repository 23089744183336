import { OrderDto } from "./order.dto"
import { BaseDto } from "./base.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { PagingDto } from "./paging.dto"
import { UserDto } from "./user.dto"
import { TenantVoucherDto } from "./tenant-voucher.dto"
import { MallOrderDto } from "./mall-order.dto"

export class CreateMallVoucherDto {
  ownerIds?: string[]
  // khmerTitle: string
  enTitle: string
  amount: number
  minOrder: number
  discountType: string
  givenType: string
  limitUsage?: number
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  tenantId: string
  type: string

  static create() {
    return new CreateMallVoucherDto()
  }
}

export class FindMallVoucherDto extends PagingDto {
  status?: string
  searchText?: string
}

export class ExportHistoryDto {
  startDate?: Date
  endDate?: Date
}

export class MallVoucherDto extends BaseDto {
  title: string
  // khmerTitle: string
  enTitle: string
  amount: number
  limitUsage: number
  discountType: string
  endDate: Date
  givenType: string
  voucherCode: string
  enabled: boolean
  creator: UserDto
  type: string
  tenantId: string
  
  static create() {
    return new MallVoucherDto()
  }
}

export class MallVoucherDetailDto extends BaseDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  limitUsage: number
  discountType: string
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  minOrder: number
  givenType: string
  ownerIds: string[]
  type: string
  tenantId: string

  static create() {
    return new MallVoucherDetailDto()
  }
}

export class VoucherHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  voucherTotal: number
  voucherDiscountAmount: number
  voucherDiscountType: string
  mallOrder: MallOrderDto
  mallVoucher: MallVoucherDto

  static create() {
    return new VoucherHistoryDto()
  }
}