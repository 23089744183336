import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { ProductDto } from "./product.dto"
import { UserDto } from "./user.dto"

export class ProductReviewDto extends BaseDto {
  rating: number
  review: string
  visibleToPublic: boolean
  owner: UserDto
  images: FileDto[]
  product: ProductDto
}

export class ProductReviewPagingDto {
  count: number
  rows: ProductReviewDto[]
  ratings: object
}

export class FindProductReviewDto extends PagingDto {
  ownerId?: string
  searchText?: string
}