import { CouponProductApplyType, CouponType } from "../../+merchant/+coupon/edit-coupon/edit-coupon.component.util"
import { BaseDto } from "./base.dto"
import { BrandDto } from "./brand.dto"
import { CategoryDto } from "./category.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { FileDto } from "./file.dto"
import { OrderDto } from "./order.dto"
import { PagingDto } from "./paging.dto"
import { ProductDetailDto, ProductDto } from "./product.dto"
import { TenantDto } from "./tenant.dto"
import { UserDto } from "./user.dto"
import { assign } from 'lodash';
import * as _ from 'lodash';

export class TenantCouponCodeDto {
  id: string
  limitUsage: number
  usedCount: number
  couponCode: string
  isValid: boolean
}

export class CreateTenantCouponProductSpecificDto {
  titleEn: string
  titleKm: string
  termConditionKm?: string
  termConditionEn?: string
  amount: number
  minOrder: number
  type: CouponType
  discountType: string
  startDate: string
  endDate: string
  limitUsage: number
  userType: string
  userIds?: string[]
  productIds: string[]
  prefix?: string

  static create() {
    return new CreateTenantCouponProductSpecificDto()
  }
}

export class CreateTenantCouponDto {
  titleEn: string
  titleKm: string
  amount: number
  minOrder: number
  termConditionEn?: string
  termConditionKm?: string
  type: CouponType
  discountType: string
  startDate: string
  endDate: string
  productApplyType: CouponProductApplyType
  limitUsage: number
  userType: string
  userIds?: string[]
  categoryIds?: string[]
  brandIds?: string[]
  productIgnoreIds?: string[]
  prefix?: string

  static create() {
    return new CreateTenantCouponDto()
  }
}

export class FindTenantCouponDto extends PagingDto {
  status?: string
  searchText?: string
  startedDate?: string
  endedDate?: string
}

export class TenantCouponDto extends BaseDto {
  titleEn: string
  titleKm: string
  termConditionEn: string
  termConditionKm: string
  amount: number
  discountType: string
  limitUsage: number
  products: ProductDto[]
  status: string
  couponCodes: TenantCouponCodeDto[]
  brands: BrandDto[]
  categories: CategoryDto[]
  couponUsers: UserDto[]
  numberOfCode: number
  productType: string
  userType: string
  startDate: string
  endDate: string
  minOrder: number
  prefix: string
  code: string

  static create() {
    const coupon = new TenantCouponDto
    coupon.discountType = 'percentage'
    coupon.productType = 'all'
    coupon.userType = 'general'

    return coupon
  }
}

export class TenantCouponHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  couponTotal: number
  discountAmount: number
  discountType: string
  tenantOrder: OrderDto
  couponCode: string
  tenantCoupon: TenantCouponDto

  static create() {
    return new TenantCouponHistoryDto()
  }
}

export class CreateTenantCouponProduct {
  productIds: string[]
}

export class CreateTenantCouponCategory {
  categoryId: string
}

export class CreateTenantCouponBrand {
  brandId: string
}
export enum DiscountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}

export class FindTenantCouponProductDto extends PagingDto {
  searchText?: string
  enabled?: string
  categoryIds?: string[]
  brandIds?: string[]
  discounted?: string
}

export class TenantCouponUserDto extends BaseDto {
  fullName: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: DateOfBirthDto
  claimedDate: string
  enabled: boolean
  roleNames: string[]

  static create(userDto?: UserDto) {
    const user = new UserDto()
    user.fullName = user?.fullName ?? ''
    user.email = user?.email ?? ''
    user.firstName = user?.firstName ?? ''
    user.lastName = user?.lastName ?? ''
    user.phoneNumber = user?.phoneNumber ?? ''
    user.gender = user?.gender ?? ''
    user.dateOfBirth = user?.dateOfBirth ?? null
    user.enabled = user?.enabled ?? null
    user.roleNames = user?.roleNames ?? []
    user.deletedAt = user?.deletedAt ?? null
    user.createdAt = user?.createdAt ?? null
    user.id = user?.id ?? null

    return user
  }
}

export class TenantCouponBirthdayUserDto extends BaseDto {
  coupon: TenantCouponDto
  owner: UserDto
  tenant: TenantDto
  alreadyApplied: boolean

  static create() {
    return _.assign<TenantCouponBirthdayUserDto>({
      coupon: null,
      owner: UserDto.create(),
      tenant: TenantDto.create(),
      alreadyApplied: null,
    })
  }
}

export class FindTenantCouponUserDto extends PagingDto {
  searchText?: string
  startDate?: Date
  endDate?: Date
  alreadyApplied?: boolean
  roleName?: string
}

export class CreateOrUpdateTenantCouponUserDto {
  userIds: string[]
}


export class exportCouponDto {
  startDate: string
  endDate: string
}

export class exportCouponHistoryDto {
  startDate: string
  endDate: string
}