import { Pipe, PipeTransform } from '@angular/core'

import * as _ from 'lodash' 

@Pipe({name: 'removeDelPipe'})
export class RemoveDeletedPipe implements PipeTransform {
  public transform(value: string) {
    const values = _.split(value, '|Del|')
    console.log(values)
    return values[0]
  }
}