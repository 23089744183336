import { FileDto } from './file.dto'

export class ProfileDto {
  fullName: string
  email?: string
  phoneNumber: string
  photo: FileDto
  roleNames: string[]

  static create() {
    return new ProfileDto()
  }
}

export class UpdateProfileDto {
  public fullName: string
  public email: string
  public phoneNumber: string

  static create(data: UpdateProfileDto) {
    return Object.assign(new UpdateProfileDto(), data)
  }
}