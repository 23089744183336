import { TenantDto } from "."
import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"

export class FindFloorMapDto extends PagingDto {

}

export class FloorMapDto extends BaseDto {
  title: string
  image: FileDto
  enabled: boolean
  mobileImage: FileDto
  tenants: TenantDto[]

  static create() {
    return new FloorMapDto()
  }
}

export class CreateFloorMapDto {
  title?: string
  imageId?: string
  mobileImageId?: string
  enabled?: boolean
}

export class UpdateSortFloorMapDto {
  floorMapIds: string[]
}