import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { MerchantUpdateTenantDto, TenantDto } from '../dto'

@Injectable()
export class TenantService {
  constructor(
    private httpService: HttpService) {
  }

  async findOne() {
    return await this.httpService.get<TenantDto>('/merchant/v1/tenant')
  }

  async update(merchantUpdateTenant: MerchantUpdateTenantDto) {
    return await this.httpService.put<Boolean>('/merchant/v1/tenant', merchantUpdateTenant)
  }

  async setEnable(enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>('/merchant/v1/tenant/enable')
    }

    return await this.httpService.patch<Boolean>('/merchant/v1/tenant/disable')
  }

  async setMobileImages(fileIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/tenant/mobile-images', { fileIds })
  }

  async setImages(fileIds: string[]) {
    return await this.httpService.put<Boolean>('/merchant/v1/tenant/images', { fileIds })
  }
}
