import { BaseDto } from "./base.dto"
import { PagingDto } from "./paging.dto"

export class FindCustomerInquiryDto extends PagingDto {
  searchText?: string
  startDate?: Date
  endDate?: Date
}

export class CustomerInquiryDto extends BaseDto {
  name: string
  phoneNumber: string
  email: string
  comment: string

  static create() {
    return new CustomerInquiryDto()
  }
}